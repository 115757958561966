// import appointments from "../../components/appointments"
// import Splash from "../../components/Splash"
// import Calendar from "../../assets/images/calendar.svg";
import HandPhone from "../../assets/images/hand-phone.png";
import WomanBus from "../../assets/images/woman-bus.png";
import WomanBusMobile from "../../assets/images/woman-bus-mobile.png";
import GirlAppointments from "../../assets/images/girl-appointments.png";
import GirlAppointmentsMobile from "../../assets/images/girl-appointments-mobile.png";
import PhoneApp from "../../assets/images/phone-app.png";
import CalendarSmall from "../../assets/images/calendar-small.png";
import ArrowPrevious from "../../assets/images/arrow-previous.svg";
import ArrowNext from "../../assets/images/arrow-next.svg";

// import Availability from "../../assets/images/availability.svg";
import Availability from "../../assets/images/available.svg";
import AvailabilityMobile from "../../assets/images/available-mobile.svg";
// import PrintScreens from "../../assets/images/print-screens.svg";
// import Print1 from "../../assets/images/carosel/1.svg";
// import Print2 from "../../assets/images/carosel/2.svg";
// import Print3 from "../../assets/images/carosel/3.svg";
// import Print4 from "../../assets/images/carosel/4.svg";
// import Print5 from "../../assets/images/carosel/5.svg";
// // import Print6 from "../../assets/images/carosel/6.png";
// import CalendarIcon from "../../assets/icons/Calendar-icon.svg";
// import ClockIcon from "../../assets/icons/Clock-icon.svg";
import Tutorial from "../../assets/images/tutorial.svg";
import ComoCriarCompromisso from "../../assets/images/como-criar-compromisso.png";
import ComoInstalarIos from "../../assets/images/como-instalar-ios.png";
// import AvailabileBanner from "../../assets/images/available-banner.svg";
// import AvailabileBannerMobile from "../../assets/images/acesseoapp.svg";
// import Facebook from "../../assets/icons/facebook-f-brands.svg";
// import Instagram from "../../assets/icons/instagram-brands.svg";
// import Linkedin from "../../assets/icons/linkedin-in-brands.svg";
// import Youtube from "../../assets/icons/youtube-brands.svg";
import Logo from '../../assets/images/logotipo.svg';
import FooterFacebook from "../../assets/icons/footer-facebook-f-brands.svg";
import FooterInstagram from "../../assets/icons/footer-instagram-brands.svg";
import FooterLinkedin from "../../assets/icons/footer-linkedin-in-brands.svg";
import FooterYoutube from "../../assets/icons/footer-youtube-brands.svg";
import Slider from "react-slick";
// import React, { useEffect, useState } from "react";


export default function Home() {

  // const [width, setWidth] = useState(window.innerWidth);
  // const [isMobile, setIsMobile] = useState(width <div 767);

  // useEffect(() => {
  //   function handleResize() {
  //     setWidth(window.innerWidth);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [width]);

  // useEffect(() => {
  //   width < 767 && handleSideNavToggle();
  // }, [width]);

  // function handleSideNavToggle() {
  //   console.log("toggle it");
  //   setIsMobile(true);
  // }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          // background: "gray",
          // borderRadius: "50%",
        }}
        onClick={onClick}
      >
        <img src={ArrowNext} alt='acesse'></img>
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          // background: "gray",
          // borderRadius: "50%",
        }}
        onClick={onClick}
      >
        <img src={ArrowPrevious} alt='acesse'></img>
      </div>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <>
      <div id="first-section"></div>

      {/* <Splash
        isVisible={true}
      /> */}

      {/* <appointments
        fetchOnlyActive={true}
      /> */}
      <div className="first-section">
        {/* <div id="first-section"></div> */}
        <div className="container-left">
          <div className="text dark">
            Controle total dos seus compromissos
          </div>
          <div className="text smaller">
            Na rua, em casa, no escritório... em qualquer lugar<br /> Seu mais novo aliado na gestão de compromissos
          </div>

          <div className="button-container">
            <button
              onClick={() => {
                window.open("https://telembroapp.com/", "_new")
              }}
            >
              Acessar o app
            </button>
          </div>

          <div className="availability-container">
            <div>
              <img src={Availability} alt='availability' className="availability desktop"></img>
              <img src={AvailabilityMobile} alt='availability' className="availability mobile"></img>
            </div>
          </div>
        </div>

        <img src={HandPhone} alt='calendar' className="calendar"></img>
      </div>

      <div className="second-section">
        <div id="second-section"></div>
        <div className="smaller-container">
          <div className="tutorial-container">
            <div className="tutorial-row">
              <div className="tutorial">
                <div className="button-container mobile">
                  <button
                    onClick={() => {
                      window.open("https://telembroapp.com/", "_new")
                    }}
                  >
                    Acessar o app
                  </button>
                </div>
                <div className="left">
                  <div className="image">
                    <img src={Tutorial} alt='calendar' className=""></img>
                  </div>
                </div>
                <div className="right">
                  <div className="title">
                    Gerencie Compromissos de Forma Inteligente
                  </div>
                  <div className="text">
                    Crie compromissos em menos de 1 minuto!
                    Planeje sua semana com facilidade! Veja os próximos 7 dias organizados na tela inicial, para uma visão clara e prática dos seus compromissos.
                  </div>
                  <div className="button-container desktop">
                    <button
                      onClick={() => {
                        window.open("https://telembroapp.com/", "_new")
                      }}
                    >
                      Acessar o app
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="title-container">
          Conheça Nosso App
        </div>
        <div className="carousel">
          <Slider {...settings}>
            <div>
              <img src={Print1} alt='print' className="print-screens"></img>
            </div>
            <div>
              <img src={Print2} alt='print' className="print-screens"></img>
            </div>
            <div>
              <img src={Print3} alt='print' className="print-screens"></img>
            </div>
            <div>
              <img src={Print4} alt='print' className="print-screens"></img>
            </div>
            <div>
              <img src={Print5} alt='print' className="print-screens"></img>
            </div>
          </Slider>
        </div>
        <div id="third-section"></div>
        <div className="title-container dark">
          Nossas Funcionalidades
        </div>
        <div className="smaller-container">
          <div className="functionalities-container">
            <div className="functionality">
              <div className="top">
                <div className="image">
                  <img src={CalendarIcon} alt='calendar' className=""></img>
                </div>
                <div className="title">
                  Datas de vencimento de documentos
                </div>
              </div>
              <div className="bottom">
                Arquivamento de datas de vencimento de qualquer documento (passaporte, visto, carteira de motorista etc.)
              </div>
            </div>
            <div className="functionality">
              <div className="top">
                <div className="image">
                  <img src={ClockIcon} alt='calendar' className=""></img>
                </div>
                <div className="title">
                  Datas de vencimento de contratos
                </div>
              </div>
              <div className="bottom">
                Arquivamento de datas de vencimento de qualquer contrato, em especial aqueles com fidelidade e que tenham renovações automáticas
              </div>
            </div>
            <div className="functionality">
              <div className="top">
                <div className="image">
                  <img src={CalendarIcon} alt='calendar' className=""></img>
                </div>
                <div className="title">
                  Datas de vencimento de boletos
                </div>
              </div>
              <div className="bottom">
                Arquivamento de datas de vencimento de qualquer tipo de documento/compromisso de pagamento
              </div>
            </div>
            <div className="functionality">
              <div className="top">
                <div className="image">
                  <img src={ClockIcon} alt='calendar' className=""></img>
                </div>
                <div className="title">
                  Compartilhamento de compromissos
                </div>
              </div>
              <div className="bottom">
                Arquivamento de contas em comum de um casal / uma família (por meio da funcionalidade de compartilhamento)
              </div>
            </div>
          </div>
          <div className="big-text">
          </div>
          <div className="button-container">
            <button
              onClick={() => {
                window.open("https://telembroapp.com/", "_new")
              }}
            >
              Acesse o app
            </button>
          </div>
        </div> */}
      </div>

      <div className="third-section">
        <div id="third-section"></div>
        <div className="left-side">
          <div className="left-side-content">
            <div className="title">
              Personalize e categorize
            </div>
            <div className="subtitle">
              Deixe seus compromissos com a sua cara! Adicione tags com cores, crie categorias personalizadas e organize tudo de um jeito que faz sentido para você.
            </div>
            <div className="button-container">
              <button
                onClick={() => {
                  window.open("https://telembroapp.com/", "_new")
                }}
              >
                Acessar o app
              </button>
            </div>
          </div>
        </div>
        <div className="right-side">
          <img src={WomanBus} alt='acesse' className="desktop"></img>
          <img src={WomanBusMobile} alt='acesse' className="mobile"></img>
        </div>
      </div>

      <div className="fourth-section">
        <div id="fourth-section"></div>
        <div className="title-container">
          Notificações em tempo real
        </div>
        <div className="subtitle-container">
          Fique sempre por dentro! Receba notificações sobre seus compromissos, lembretes importantes e atualizações em tempo real, garantindo que nada passe despercebido.
        </div>
        <div className="smaller-container">
          <img src={GirlAppointments} alt='calendar' className="desktop"></img>
          <img src={GirlAppointmentsMobile} alt='calendar' className="mobile"></img>
        </div>
      </div>

      <div className="fifth-section">
        <div id="fifth-section"></div>
        <div className="left-side">
          <div className="left-side-content">
            <div className="title">
              Calendário visual
            </div>
            <div className="subtitle">
              Nosso calendário visual utiliza cores diferentes para identificar compromissos, tornando o planejamento mais simples e intuitivo.
            </div>
            <div className="button-container desktop">
              <button
                onClick={() => {
                  window.open("https://telembroapp.com/", "_new")
                }}
              >
                Acessar o app
              </button>
            </div>
          </div>
        </div>
        <div className="right-side">
          <img src={CalendarSmall} alt='acesse' className="desktop"></img>
          <img src={CalendarSmall} alt='acesse' className="mobile"></img>
        </div>
        <div className="button-container mobile">
          <button
            onClick={() => {
              window.open("https://telembroapp.com/", "_new")
            }}
          >
            Acessar o app
          </button>
        </div>
      </div>

      <div className="sixth-section">
        <div id="sixth-section"></div>
        <div className="smaller-container">
          <div className="tutorial-container">
            <div className="tutorial-row">
              <div className="tutorial">
                <div className="left">
                  <div className="image">
                    <img src={PhoneApp} alt='calendar' className=""></img>
                  </div>
                </div>
                <div className="right">
                  <div className="title">
                    Converse em tempo real
                  </div>
                  <div className="text">
                    Com mensagens em tempo real, todos os participantes do compromisso podem trocar informações instantaneamente. Discuta detalhes, compartilhe atualizações e resolva dúvidas no momento, garantindo alinhamento e agilidade na comunicação.
                  </div>
                  <div className="button-container">
                    <button
                      onClick={() => {
                        window.open("https://telembroapp.com/", "_new")
                      }}
                    >
                      Acessar o app
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="seventh-section">
        <div id="seventh-section"></div>
        <div className="title-container">
          Video Tutoriais
        </div>
        <div className="carousel">
          <Slider {...settings}>
            <div>
              <a href="https://youtu.be/JJVSNSEfr-Y" target="_blank" rel="noreferrer">
                <img src={ComoCriarCompromisso} alt='print' className="print-screens"></img>
              </a>
            </div>
            <div>
              <a href="https://youtu.be/04FhdbRvlVk " target="_blank" rel="noreferrer">
                <img src={ComoInstalarIos} alt='print' className="print-screens"></img>
              </a>
            </div>
            <div>
              <a href="https://youtu.be/JJVSNSEfr-Y" target="_blank" rel="noreferrer">
                <img src={ComoCriarCompromisso} alt='print' className="print-screens"></img>
              </a>
            </div>
            <div>
              <a href="https://youtu.be/04FhdbRvlVk " target="_blank" rel="noreferrer">
                <img src={ComoInstalarIos} alt='print' className="print-screens"></img>
              </a>
            </div>
            <div>
              <a href="https://youtu.be/JJVSNSEfr-Y" target="_blank" rel="noreferrer">
                <img src={ComoCriarCompromisso} alt='print' className="print-screens"></img>
              </a>
            </div>
            <div>
              <a href="https://youtu.be/04FhdbRvlVk " target="_blank" rel="noreferrer">
                <img src={ComoInstalarIos} alt='print' className="print-screens"></img>
              </a>
            </div>
          </Slider>
        </div>

      </div>

      <div className="footer">
        <div className="footer-content">
          <div className="footer-content-left">
            <div className="top-container">
              <div className="logo-container">
                <img src={Logo} alt='logo' className=""></img>
              </div>
              <div className="text-container">
                <b>Controle compromissos em qualquer lugar! </b>
                Seu mais novo aliado na gestão de compromissos
              </div>
            </div>
            <div className="bottom-container">
              <div className="social-networks-container top">
                Acompanhe-nos nas redes sociais
              </div>
              <div className="social-networks-container bottom">
                <div className="social-network">
                  <a href="https://www.instagram.com/telembro.app/ " target="_blank" rel="noreferrer">
                    <img src={FooterInstagram} alt='social' className=""></img>
                  </a>
                </div>
                <div className="social-network">
                  <a href="https://www.facebook.com/telembroapp" target="_blank" rel="noreferrer">
                    <img src={FooterFacebook} alt='social' className=""></img>
                  </a>
                </div>
                <div className="social-network">
                  <a href="https://www.linkedin.com/company/telembro-app/" target="_blank" rel="noreferrer">
                    <img src={FooterLinkedin} alt='social' className=""></img>
                  </a>
                </div>
                <div className="social-network">
                  <a href="https://youtu.be/JJVSNSEfr-Y" target="_blank" rel="noreferrer">
                    <img src={FooterYoutube} alt='social' className=""></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content-right">
            <div className="right-content-container">
              <div className="left-side">
                <div className="title-container">
                  Como instalar
                </div>
                <div className="links-container">
                  <div className="link-container">
                    <a href="https://kroonar.s3.amazonaws.com/Tutorial+iOS.pdf" target="_blank" rel="noreferrer">
                      iPhone
                    </a>
                  </div>
                  <div className="link-container">
                    <a href="https://kroonar.s3.amazonaws.com/Tutorial+Android.pdf" target="_blank" rel="noreferrer">
                      Android
                    </a>
                  </div>
                  <div className="link-container">
                    <a href="https://kroonar.s3.amazonaws.com/Tutorial+Desktop.pdf" target="_blank" rel="noreferrer">
                      Desktop
                    </a>
                  </div>
                </div>
              </div>
              <div className="separator">

              </div>
              <div className="right-side">
                <div className="title-container">
                  Fale conosco
                </div>
                <div className="contacts-container">
                  <div className="contact-container">
                    Para dúvidas gerais:
                    <a
                      href='#t'
                      className='link'
                      onClick={() => window.location.href = "mailto:info@telembro.com"}
                      onKeyDown={() => window.location.href = "mailto:info@telembro.com"}
                    >
                      info@telembro.com
                    </a>
                  </div>
                  <div className="contact-container">
                    Para falar com o DPO:
                    <a
                      href='#t'
                      className='link'
                      onClick={() => window.location.href = "mailto:telembro.dpo@gmail.com"}
                      onKeyDown={() => window.location.href = "mailto:telembro.dpo@gmail.com"}
                    >
                      telembro.dpo@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="last-container">
          <div className="date-company">
            ©{(new Date().getFullYear())} - TeLembro®
          </div>
        </div>
      </div>
    </>
  )
};